<template>
  <div class="answerCard">
    <div class="warp">
      <template v-for="item in list">
        <p class="title">{{ item.name }}</p>
        <div class="content">
          <template v-for="(val,index) in item.children">
            <p :class="{'sign':true,'doneQuestion':val.doneAnswerIds||val.doneAnswer||val.answerId,'curQuestion':completeNumber===val.subjectId}" @click="jump(val)" v-if="!val.subjectList.length">
              {{ (index + 1) }}
              <span v-if="val.isSign == '1'"></span>
            </p>
            <template v-else v-for="(val1,index1) in val.subjectList">
              <p :class="{'sign':true,'doneQuestion':val1.doneAnswerIds||val1.doneAnswer||val1.answerId,'curQuestion':completeNumber===val1.subjectId}" @click="jump(val,val1,index1)">
                {{ (index + 1) +'.'+ (index1 + 1) }}
                <span v-if="val.isSign == '1'"></span>
              </p>
            </template>
          </template>
        </div>
      </template>
    </div>
    <div class="examText_main_left_explain">
      <div class="examText_main_left_explain_header">
        <span>注：</span><span>以题为例</span>
      </div>
      <div class="examText_main_left_explain_view">
        <div class="examText_main_left_explain_view_item">
          <p>当前题</p>
          <p></p>
        </div>
        <div class="examText_main_left_explain_view_item">
          <p>未做</p>
          <p></p>
        </div>
      </div>
      <div class="examText_main_left_explain_view">
        <div class="examText_main_left_explain_view_item">
          <p>已做</p>
          <p></p>
        </div>
        <div class="examText_main_left_explain_view_item">
          <p>标记题</p>
          <p class="sign">
            <span></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  subject_list,
  subjectPaperList
} from "@/api/exam";

export default {
  name: 'answerCard',
  components: {},
  props: ["AnswerList"],
  watch: {
    AnswerList: {
      handler(val) {
        this.getData(val);
      },
      deep: true
    }
  },
  data() {
    return {
      list: [],
      typeList: [
        {type: '1', name: '单选题'},
        {type: '2', name: '多项选择题'},
        {type: '3', name: '判断题'},
        {type: '4', name: '不定项选择题'},
        {type: '5', name: '计算分析题'},
        {type: '6', name: '综合题'},
        {type: '7', name: '简答题'},
        {type: '8', name: '计算回答题'},
        {type: '9', name: '案例分析题'},
        {type: '10', name: '计算题'},
        {type: '11', name: '综合分析题'}
      ],
      completeNumber: 0,
      questionData: {}
    }
  },
  mounted(){
    // setTimeout(()=>{
    //   this.getData(this.AnswerList);
    // },1500)
  },
  methods: {
    getData(data) {
      this.list = [];
      // this.completeNumber = data.finallyAnswerId||data.paperSubjectList[0].subjectId;
      this.completeNumber = data.finallyAnswerId||data.paperSubjectList[0].subjectId;
      this.questionData = data.paperSubjectList;
      this.typeList.map(v => {
        if(data.paperSubjectList) {
          let paper = data.paperSubjectList.filter(val => val.classify === v.type);
          if (paper.length) {
            this.list.push({name: v.name, children: paper});
          }
        }
      })
    },
    jump(data,subjectList,index1) {
      this.completeNumber = data.subjectId;
      this.AnswerList.finallyAnswerId = data.subjectId;
      let index = 0;
      this.questionData.map((v,i)=>{
        if(v.subjectId === data.subjectId) {
          index = i;
        }
      })
      if(subjectList) {
        this.completeNumber = subjectList.subjectId;
        this.AnswerList.finallyAnswerId = subjectList.subjectId;
        this.$emit('jumpTo',index,subjectList.subjectId,index1);
      }else {
        this.completeNumber = data.subjectId;
        this.$emit('jumpTo',index);
      }
    },
    jumpTo(data,two) {
      this.questionData.map((v,i)=>{
        if(i === data) {
          if(v.subjectList.length) {
            this.AnswerList.finallyAnswerId = v.subjectList[two||0].subjectId;
            this.completeNumber = v.subjectList[two||0].subjectId;
          }else {
            this.AnswerList.finallyAnswerId = v.subjectId;
            this.completeNumber = v.subjectId;
          }
        }
      })
    },
    goQuestion(data) {
      this.completeNumber = data.subjectId;
    }
  }
}
</script>

<style lang="less" scoped>
.answerCard {
  .warp {
    .title {
      width: 100%;
      padding: 0 20px 5px 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #DDDDDD;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;

      p {
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border: 1px solid #DDDDDD;
        border-radius: 50%;
        margin-right: 5px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        background-color: #FFFFFF;
        cursor: pointer;
      }

      .doneQuestion {
        background-color: #DCE3FF !important;
        color: #5F7CFD;
      }

      .curQuestion {
        background-color: #5F7CFD !important;
        color: #FFFFFF !important;
      }
    }
  }

  .sign {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    width: 18px;
    height: 18px;

    span {
      width: 5px;
      height: 4px;
      font-size: 8px;
      line-height: 32px;
      background: orange;
      position: absolute;
      right: 0;
      bottom: 0;
      z-Index: 2;
      padding: 0 2em;
      -webkit-transform-origin: left top;
      -moz-transform-origin: left top;
      transform-origin: left top;
      -webkit-transform: translate(29.29%, 100%) rotate(-45deg);
      -moz-transform: translate(29.29%, 100%) rotate(-45deg);
      transform: translate(29.29%, 100%) rotate(-45deg);
      text-indent: 0;
    }
  }
}
</style>