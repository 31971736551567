<template>
  <div class="tikuAnswer">
    <div class="tikuAnswer_header">
      <div class="tikuAnswer_header_box">
        <p>
          <span style="margin-left: 0">中国会计网</span>
        </p>
        <p>
          <span
            v-if="
              tabIndex == '3' ||
              tabIndex == '4' ||
              tabIndex == '5' ||
              twoIindex == '1'
            "
            >试卷总分{{ AnswerList.paperTotalScore }}分</span
          >
          <span
            v-if="
              tabIndex == '3' ||
              tabIndex == '4' ||
              tabIndex == '5' ||
              twoIindex == '1'
            "
            >答题时间{{ AnswerList.paperAnswerTime }}分钟</span
          >
        </p>
      </div>
    </div>

    <!-- title -->
    <div class="tikuAnswer_title">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="{
            path: '/tiku_examcenter',
            query: { secondId: this.secondId, firstId: this.firstId },
          }"
          >在线题库</el-breadcrumb-item
        >
        <el-breadcrumb-item
          v-if="tabIndex == 3 || tabIndex == 4 || tabIndex == 5"
          >{{ examInfo.testPaperName }}</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else>{{ examInfo.nodeName }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tikuAnswer_title_time">
        <img src="@/assets/exam/time.png" alt="" />
        <span class="tikuAnswer_title_time_text">已耗时：{{ str }}</span>
        <el-button type="danger" @click="submitPaper">交卷</el-button>
      </div>
    </div>

    <!-- main -->
    <div class="tikuAnswer_main">
      <!-- main_left -->
      <div class="tikuAnswer_main_left" v-if="AnswerList">
        <answerCard ref="answerCard" :AnswerList="AnswerList" @jumpTo="jumpTo"></answerCard>
      </div>

      <!-- main_right -->
      <div class="tikuAnswer_main_right"  v-if="AnswerList">
        <questionArea
          :AnswerList="AnswerList"
          ref="questionArea"
          @allEventFn="allEventFn"
          @submitPaper="submitPaper"
          @changePaper="changePaper"
        ></questionArea>
      </div>
    </div>
  </div>
</template>

<script>
import answerCard from "@/views/tiku/components/answerCard";
import questionArea from "@/views/tiku/components/questionArea";
import { Loading } from "element-ui";
import {
  subject_list,
  save_subject,
  add_sign,
  add_collect,
  deleted_collect,
  assignment_paper,
  subjectPaperList,
  savePaper_subject,
  assignment_exercise,
  error_exercise_subjectList,
  error_paper_subjectList,
  analysis_list,
} from "@/api/exam";

export default {
  name: "tiku1",
  components: {
    answerCard,
    questionArea,
  },
  data() {
    return {
      AnswerList: [],
      hour: 0, //定义时，分，秒，毫秒并初始化为0；
      minute: 0,
      second: 0, //秒
      time: "",
      str: "",
      second_time: 0,
      secondId: "",
      firstId: "",
      tabIndex: "",
      twoIindex: "",
      examInfo: {},
      testSecond_time: 0,
      answerTimers: "",
      paperAnswerTime: "",
    };
  },
  created() {
    this.loading = Loading.service({
      text: "Loading...",
      background: "rgba(0, 0, 0, 0.1)",
    });
  },
  mounted() {
    var that = this;
    var pageQuery = that.$route.query;
    var examTextInfo = localStorage.getItem("tiku_examText");
    if (examTextInfo) {
      examTextInfo = JSON.parse(examTextInfo);
      that.examInfo = examTextInfo.chapterListItem;
      if (pageQuery && pageQuery.value) {
        this.num = pageQuery.value;
      } else {
        this.num = examTextInfo.value;
      }
      that.secondId = examTextInfo.secondId;
      that.firstId = examTextInfo.firstId;
    }
    that.tabIndex = pageQuery.tabIndex;
    this.init();

  },
  watch: {},
  methods: {
    /*答题卡跳转题目*/
    jumpTo(index,subjectList,index1) {
      this.$refs.questionArea.setActiveQuestion(index,subjectList,index1);
    },
    initTestReportInfo(val,num){
      var testReportInfo = new Object();
      let that = this;
      if(val == 2){
        testReportInfo = {
          examInfo: that.examInfo,
          num: num,
          twoIindex: that.twoIindex,
          tabIndex: that.tabIndex,
          firstId: that.firstId,
          secondId: that.secondId,
          testPaperId: that.examInfo.testPaperId,
        }
      }else{
        testReportInfo = {
          examInfo: that.examInfo,
          num: num,
          twoIindex: that.twoIindex,
          tabIndex: that.tabIndex,
          firstId: that.firstId,
          secondId: that.secondId,
        }
          
      }
     
      localStorage.setItem("testReport", JSON.stringify(testReportInfo))
    },
    submitPaper(){ // 交卷
       // 路由参数
      let pageQuery = this.$route.query;
      let tabsIndex = pageQuery.tabIndex;
      // 二级判断 做题记录的章节还是考试
      var examTextInfo = localStorage.getItem("tiku_examText");
      let examText = JSON.parse(examTextInfo);
      let items = examText.chapterListItem;
      if(tabsIndex == '1'){
        var obj = {
          nodeId: items.nodeId,
          nodeType: '1',
          terrace:'2',
        }
        assignment_exercise(obj).then(res => {
          if (res.data.code == 0) {
            this.initTestReportInfo(1,1);
            this.$router.push({
                path: '/tikuReport',
                query: {
                  nodeId: items.nodeId,
                  nodeType: '1',
                }
            });
          }
        })  
      }else if(tabsIndex=='3' || tabsIndex=='4' || tabsIndex=='5'){
        let testPaperId = pageQuery.testPaperId;
        var obj = {
          testPaperId: testPaperId,
          terrace:'2',
        }
        assignment_paper(obj).then(res => {
          if (res.data.code == 0) {
            this.initTestReportInfo(2,2);
            this.$router.push({
              path: '/tikuReport',
              query: {
                testPaperId: testPaperId,
              }
            });
          }
        })
      }else if(tabsIndex=='6' || tabsIndex=='8'){
        if(examText.twoIindex == "0"){
          var obj = {
            nodeId: items.nodeId,
            nodeType: '1',
            terrace:'2',
          }
          assignment_exercise(obj).then(res => {
            if (res.data.code == 0) {
              this.initTestReportInfo(1,2);
              this.$router.push({
                  path: '/tikuReport',
                  query: {
                    nodeId: items.nodeId,
                    nodeType: '1',
                  }
              });
            }
          })  
        }else{
          let testPaperId = pageQuery.testPaperId;
          var obj = {
            testPaperId: testPaperId,
            terrace:'2',
          }
          assignment_paper(obj).then(res => {
            if (res.data.code == 0) {
              this.initTestReportInfo(2,2);
              this.$router.push({
                path: '/tikuReport',
                query: {
                  testPaperId: testPaperId,
                }
              });
            }
          })
        }
      }  



    },
    changePaper(index,two) {
      this.$refs.answerCard.jumpTo(index,two);
    },
    getSubjectPaperList(obj) {
      //获取试卷列表
      var that = this;
      var obj = {
        testPaperId: obj.testPaperId,
        type: obj.type,
        terrace:'2'
      };
      subjectPaperList(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.paperAnswerTime = res.data.data.paperAnswerTime;
            this.testSecond_time = this.AnswerList.timeLength;
            this.testMounted =
              Number(res.data.data.paperAnswerTime * 60) -
              res.data.data.timeLength; //总共剩余秒数

            this.testSecond = this.testMounted % 60;
            this.testSecond_time = res.data.data.timeLength;
            this.recordTime = res.data.data.timeLength;
            this.testMinute = Math.floor(this.testMounted / 60);
            if (this.testMinute > 60) {
              this.testHour = Math.floor(Number(this.testMinute) / 60);
              this.testMinute = this.testMinute % 60;
            } else {
              if (this.testMinute >= 60) {
                this.testMinute = Number(this.testMinute) % 60;
              }
              this.testHour = 0;
            }

            let subjectListData = res.data.data.paperSubjectList;
            let obj = {
              1:[],
              2:[],
              3:[],
              4:[],
              5:[],
              6:[],
              7:[],
              8:[],
              9:[],
              10:[],
              11:[],
            };
            subjectListData.forEach((v,i)=>{
              obj[v.classify].push(1);
              v.questionIndex = obj[v.classify].length;
            })
            this.AnswerList = res.data.data;

            this.loading.close();
            this.deltimer();
            var examTextInfo = localStorage.getItem("tiku_examText");
            if (examTextInfo) {
              examTextInfo = JSON.parse(examTextInfo);
              examTextInfo.value = 1;
              localStorage.setItem('tiku_examText',JSON.stringify(examTextInfo));
            }
            this.$nextTick(()=>{
              this.$refs.questionArea.getIsSign();
              this.$refs.questionArea.getIsCollect();
              this.update_size();
            })
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    init() {
      // 路由参数
      let pageQuery = this.$route.query;
      let tabsIndex = pageQuery.tabIndex;
      // 二级判断 做题记录的章节还是考试
      var examTextInfo = localStorage.getItem("tiku_examText");
      let examText = JSON.parse(examTextInfo);
      let items = examText.chapterListItem;
      if(tabsIndex == '1'){
        this.subject_list({
          nodeId:items.nodeId,
          nodeType:'1',
          terrace:'2',
          type:this.num

        });
      }else if(tabsIndex=='3' || tabsIndex=='4' || tabsIndex=='5'){
        let testPaperId = pageQuery.testPaperId;
        this.getSubjectPaperList({
          testPaperId: testPaperId,
          terrace:'2',
          type: this.num
        });
      }else if(tabsIndex=='6' || tabsIndex=='8'){
        if(examText.twoIindex == "0"){
          this.subject_list({
            nodeId:items.nodeId,
            nodeType:'1',
            terrace:'2',
            type:this.num
          });
        }else{
          let testPaperId = pageQuery.testPaperId;
          this.getSubjectPaperList({
            testPaperId: testPaperId,
            terrace:'2',
            type: this.num
          });
        }
      }




      
    },
    getAnswerTimeLength(times) {
      let arys = times.split(":");
      let time1 = parseInt(arys[0]) * 60 * 60;
      let time2 = parseInt(arys[1]) * 60;
      let time3 = parseInt(arys[2]);
      return time1 + time2 + time3;
    },
    //历年/模拟/考前押题 保存题
    saveSHIJUAN(res) {
      if(res.answerId == undefined){
        return;
      }
      
      let alllen = this.paperAnswerTime * 60;
      var subLen = alllen - this.getAnswerTimeLength(this.answerTimers);       

      if (res.type == "radio") {
        var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          testPaperId: this.$route.query.testPaperId,
          answerId: res.answerId,
          answerText: "",
          score: "",
        };
        savePaper_subject(obj).then((result) => { this.setDataItemValue(res) });
      } else if (res.type == "checkbox") {
        var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          testPaperId: this.$route.query.testPaperId,
          answerId: res.answerId.join(","),
          answerText: "",
          score: "",
        };
        savePaper_subject(obj).then((result) => { this.setDataItemValue(res) });
      } else if (res.type == "indeter") {
       var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          testPaperId: this.$route.query.testPaperId,
          answerId: res.answerId.join(","),
          answerText: "",
          score: "",
        };
        savePaper_subject(obj).then((result) => { this.setDataItemValue(res) });
      } else if (res.type == "shortAnswer") {
        var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          testPaperId: this.$route.query.testPaperId,
          answerId: '',
          answerText: res.doneAnswer,
          score: "",
        };
        savePaper_subject(obj).then((result) => { this.setDataItemValue(res) });
      }
    },
    //章节 保存题
    saveZhangJie(res) {
      if(res.answerId == undefined){
        return;
      }
      var subLen = this.getAnswerTimeLength(this.answerTimers);
      if (res.type == "radio") {
        var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          nodeType: '1',
          answerId: res.answerId,
          answerText: "",
        };
        save_subject(obj).then((result) => { this.setDataItemValue(res) });
      } else if (res.type == "checkbox") {
        var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          nodeType: '1',
          answerId: res.answerId.join(","),
          answerText: "",
        };
        save_subject(obj).then((result) => { this.setDataItemValue(res) });
      } else if (res.type == "indeter") {
       var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          nodeType: '1',
          answerId: res.answerId.join(","),
          answerText: "",
          
        };
        save_subject(obj).then((result) => { this.setDataItemValue(res) });
      } else if (res.type == "shortAnswer") {
        var obj = {
          answerTimeLength: subLen,
          subjectId: res.subjectId,
          nodeType: '1',
          answerId: '',
          answerText: res.doneAnswer,
          
        };
        save_subject(obj).then((result) => { this.setDataItemValue(res) });
      }
    },
    setDataItemValue(res){
      //this.AnswerList.finallyAnswerId = res.subjectId;
      if(this.AnswerList.paperSubjectList[res.dataIndex].subjectList&&this.AnswerList.paperSubjectList[res.dataIndex].subjectList.length){
        let numQuestion = this.AnswerList.paperSubjectList[res.dataIndex].subjectList;
        numQuestion.map((item,index)=>{
          if(item.subjectId == res.subjectId){
            if(item.classify == '5' || item.classify == '6' || item.classify == '7' || item.classify == '8' || item.classify == '9' ){
              this.AnswerList.paperSubjectList[res.dataIndex].subjectList[index].doneAnswer = res.doneAnswer;
            }else if(item.classify == '10'){
              this.AnswerList.paperSubjectList[res.dataIndex].subjectList[index].doneAnswerIds = res.answerId;
            }else{
              this.AnswerList.paperSubjectList[res.dataIndex].subjectList[index].doneAnswerIds = res.answerId.join(",");
            }
          }
        })
      }else {
        if(res.type==='checkbox'){
          this.AnswerList.paperSubjectList[res.dataIndex].doneAnswerIds = res.answerId.join(",");
        }
      }
      
    },
    allEventFn(res) {
      
      if(res.answerId == undefined){
        return;
      }
      var pageQuery = this.$route.query;
      // 二级判断 做题记录的章节还是考试
      var examTextInfo = localStorage.getItem("tiku_examText");
      let examText = JSON.parse(examTextInfo);
      if (pageQuery.tabIndex == "1") {
        this.saveZhangJie(res);
      } else if (
        pageQuery.tabIndex == "3" ||
        pageQuery.tabIndex == "4" ||
        pageQuery.tabIndex == "5"
      ) {
        this.saveSHIJUAN(res);
      }else if(
        pageQuery.tabIndex == "6" ||
        pageQuery.tabIndex == "8" 
      ){
        if(examText.twoIindex == "0"){ //章节
          this.saveZhangJie(res);
        }else{ // 考试
          this.saveSHIJUAN(res);
        }

      }
      
    },
    // 获取章节试卷内容
    subject_list(obj) {
      var that = this;
      var obj = {
        nodeId: obj.nodeId,
        nodeType: obj.nodeType,
        type: obj.type,
        terrace:'2',
      };
      subject_list(obj).then((res) => {
        if (res.data.code == 0) {
          let subjectListData = res.data.data.paperChapterSubjectList;
          let obj = {
            1:[],
            2:[],
            3:[],
            4:[],
            5:[],
            6:[],
            7:[],
            8:[],
            9:[],
            10:[],
            11:[],
          };
          subjectListData.forEach((v,i)=>{
            obj[v.classify].push(1);
            v.questionIndex = obj[v.classify].length;
          })
          this.AnswerList = res.data.data;
          this.AnswerList.paperSubjectList = subjectListData;

          this.second = res.data.data.timeLength;
          this.second_time = res.data.data.timeLength;
          that.time = setInterval(that.timer, 1000);
          this.loading.close();
          var examTextInfo = localStorage.getItem("tiku_examText");
          if (examTextInfo) {
            examTextInfo = JSON.parse(examTextInfo);
            examTextInfo.value = 1;
            localStorage.setItem('tiku_examText',JSON.stringify(examTextInfo));
          }
          this.$nextTick(()=>{
            this.$refs.questionArea.getIsSign();
            this.$refs.questionArea.getIsCollect();
            this.update_size();
          })
        } else {
          this.loading.close();
        }
      });
    },
    update_size() {
      if (
        document.getElementsByClassName("update_font_size") != undefined &&
        document.getElementsByClassName("update_font_size") != null
      ) {
        var sizes = document.getElementsByClassName("update_font_size");

        for (var i = 0; i < sizes.length; i++) {
          if (sizes[i].getElementsByTagName("font")) {
            var classsize = sizes[i].getElementsByTagName("font");

            for (var j = 0; j < classsize.length; j++) {
              classsize[j].setAttribute("size", "4px");
            }
          }
        }
      }
    },
    // 时间计时
    timer() {
      //定义计时函数
      this.second = Number(this.second) + 1;
      this.second_time = Number(this.second_time) + 1; //秒
      if (this.second >= 60) {
        this.second = 0;
        this.minute = this.minute + 1; //分钟
      }

      if (this.minute >= 60) {
        this.minute = 0;
        this.hour = this.hour + 1; //小时
      }

      this.str =
        this.toDub(this.hour) +
        ":" +
        this.toDub(this.minute) +
        ":" +
        this.toDub(this.second); /*+""+this.toDubms(this.ms)+"毫秒"*/
      this.answerTimers = this.str;
      // document.getElementById('mytime').innerHTML=h+"时"+m+"分"+s+"秒"+ms+"毫秒";
    },
    formatSeconds(value) {
      let res = parseInt(value);
      let h = Math.floor(res / 3600) < 10 ? '0' + Math.floor(res / 3600) : Math.floor(res / 3600);
      let m = Math.floor(res / 60 % 60) < 10 ? '0' + Math.floor(res / 60 % 60) : Math.floor(res / 60 % 60);
      let s = Math.floor(res % 60) < 10 ? '0' + Math.floor(res % 60) : Math.floor(res % 60);

      let str = '';
      str += `${h}:`;
      str += `${m}:`;
      str += s;
      return str;
    },
    //倒计时
    countDown() {
      this.timerFn = setTimeout(() => {
        this.time -= 1;
        this.str = this.formatSeconds(this.time);
        this.answerTimers = this.str;

        if (this.time <= 0) {
          this.$confirm("答题时间已到，系统自动交卷", "提示", {
            confirmButtonText: "确定",
            cancelButtonText:'1',
            showCancelButton:false,
            showClose:false,
            type: "warning"
          })
          .then(() => {
            this.submitPaper();
          })
          .catch(() => {
            this.submitPaper();
          });
        }else {
          this.countDown();
        }
      }, 1000);
    },
    // 倒计时
    deltimer() {
      if((this.paperAnswerTime*60)>parseInt(this.recordTime)){
        if (parseInt(this.paperAnswerTime)) {
          this.time = this.paperAnswerTime*60 - parseInt(this.recordTime);
          this.str = this.formatSeconds(this.time);
          this.countDown();
        } 
      }else{
        this.$confirm("答题时间已到，系统自动交卷", "提示", {
            confirmButtonText: "确定",
            cancelButtonText:'1',
            showCancelButton:false,
            showClose:false,
            type: "warning"
          })
          .then(() => {
            this.submitPaper();
          })
          .catch(() => {
            this.submitPaper();
          });

      }
      
    },
    toDub(n) {
      //补0操作
      if (n < 10) {
        return "0" + n;
      } else {
        return "" + n;
      }
    },
  },
};
</script>

<style lang="less" scoped>
#update_font_size {
  /deep/ font {
    size: 4px;
  }
}
</style>
<style>
</style>