<template>
  <div class="questionArea">
    <div class="questionArea_top">
      <el-carousel
        :autoplay="false"
        ref="carouselREf"
        :initial-index="initIndex"
        :loop="false"
        arrow="never"
        @change="cardChange"
        :interval="300000000000000000000"
        indicator-position="none"
      >
        <el-carousel-item v-for="(item, index) in AnswerList.paperSubjectList" :key="index">
          <radioCom
            @answerRadio="answerRadio"
            :ref="setItemRef"
            v-if="item.classify == '1' || item.classify == '3'"
            :dataIndex="index"
            :data="item"
          ></radioCom>
          <checkboxCom
            @answerRadio="answerRadio"
            :ref="setItemRef"
            v-if="item.classify == '2'"
            :dataIndex="index"
            :data="item"
          ></checkboxCom>
          <indeterminate
            @answerRadio="answerRadio"
            @changePaper="changePaper"
            :ref="setItemRef"
            ref="changeAnswer"
            v-if="
              item.classify == '4' ||
              item.classify == '11'
            "
            :dataIndex="index"
            :data="item"
          ></indeterminate>
          <shortAnswer
            @answerRadio="answerRadio"
            @changePaper="changePaper"
            :ref="setItemRef"
            ref="changeAnswer"
            v-if="
              item.classify == '5' ||
              item.classify == '6' ||
              item.classify == '7' ||
              item.classify == '8' ||
              item.classify == '9'
            "
            :dataIndex="index"
            :data="item"
          ></shortAnswer>
          <radioGroup
            @answerRadio="answerRadio"
            @changePaper="changePaper"
            :ref="setItemRef"
            ref="changeAnswer"
            v-if="item.classify == '10'"
            :dataIndex="index"
            :data="item"
          ></radioGroup>
          
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="questionArea_bottom">
      <div class="questionArea_bottom_operate_btn">
        <el-button @click="preQuestion()">上一题</el-button>
        <el-button type="primary" @click="nextQuestion()">下一题</el-button>
      </div>
      <div class="questionArea_bottom_operate_btn">
        <div class="bottom-box" v-if="isSignTK" @click="addSing(1)">标记此题</div>
        <div class="bottom-box" v-if="!isSignTK" @click="addSing(2)">取消标记</div>
        <div>
          <el-popover
            placement="top"
            width="300"
            trigger="hover"
            popper-class="calculator"
            v-model="visible"
          >
            <div id="box">
              <table>
                <tr>
                  <td>
                    <input type="button" value="del" @click="del()" />
                  </td>
                  <td>
                    <input type="button" value="C" @click="clean()" />
                  </td>
                  <td colspan="2">
                    <input type="text" style="width: 100px" value v-model="rel" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input type="button" value="7" @click="add('7')" />
                  </td>
                  <td>
                    <input type="button" value="8" @click="add('8')" />
                  </td>
                  <td>
                    <input type="button" value="9" @click="add('9')" />
                  </td>
                  <td>
                    <input type="button" value="/" @click="add('/')" />
                  </td>
                </tr>

                <tr>
                  <td>
                    <input type="button" value="4" @click="add('4')" />
                  </td>
                  <td>
                    <input type="button" value="5" @click="add('5')" />
                  </td>
                  <td>
                    <input type="button" value="6" @click="add('6')" />
                  </td>
                  <td>
                    <input type="button" value="*" @click="add('*')" />
                  </td>
                </tr>

                <tr>
                  <td>
                    <input type="button" value="1" @click="add('1')" />
                  </td>
                  <td>
                    <input type="button" value="2" @click="add('2')" />
                  </td>
                  <td>
                    <input type="button" value="3" @click="add('3')" />
                  </td>
                  <td>
                    <input type="button" value="-" @click="add('-')" />
                  </td>
                </tr>

                <tr>
                  <td>
                    <input type="button" value="0" @click="add('0')" />
                  </td>
                  <td>
                    <input type="button" value="." @click="add('.')" />
                  </td>
                  <td>
                    <input type="button" value="+" @click="add('+')" />
                  </td>
                  <td>
                    <input type="button" value="=" v-on:click="result()" />
                  </td>
                </tr>
              </table>
            </div>

            <span slot="reference" @click="visible = !visible">
              <span style="cursor:pointer;color:#5F7CFD;">计算器</span>
            </span>
          </el-popover>
        </div>
        <div class="bottom-box" v-if="isCollectTK" @click="addCooll(1)">收藏此题</div>
        <div class="bottom-box" v-if="!isCollectTK" @click="addCooll(2)">取消收藏</div>
        <div class="bottom-box" @click="analysisQuestionn()" v-if="tabIndex == 1 && !isShowAss">答案解析</div>
        <div
          class="bottom-box"
          type="primary"
          @click="disAnalysisQuestionn()"
          v-if="tabIndex == 1 && isShowAss"
        >隐藏解析</div>
      </div>
    </div>
  </div>
</template>

<script>
import radioCom from "@/views/tiku/components/formCom/radio.vue";
import checkboxCom from "@/views/tiku/components/formCom/checkbox.vue";
import indeterminate from "@/views/tiku/components/formCom/indeterminate.vue";
import radioGroup from "@/views/tiku/components/formCom/radioGroup.vue";
import shortAnswer from "@/views/tiku/components/formCom/shortAnswer.vue";
import {
  subject_list,
  save_subject,
  add_sign,
  add_collect,
  deleted_collect,
  assignment_paper,
  subjectPaperList,
  savePaper_subject,
  assignment_exercise,
  error_exercise_subjectList,
  error_paper_subjectList,
  analysis_list
} from "@/api/exam";
export default {
  props: ["AnswerList"],
  components: {
    radioCom,
    checkboxCom,
    indeterminate,
    shortAnswer,
    radioGroup
  },
  data() {
    return {
      initIndex: 0,
      subMitList: [],
      refList: [],
      isSignTK: false,
      isCollectTK: false,
      isAss: true,
      rel: "", // 计算
      visible: false, // 是否显示计算器
      rel: "", // 计算
      tabIndex: 0,
      isShowAss:false,
    };
  },
  name: "questionArea",
  watch: {
    AnswerList: {
      handler(val) {
        this.setIndex(val);
      },
      deep: true
    }
  },
  created() {
    this.$store.commit("setQuestionSwipeIndex", { index: -1, isShow: false });
  },
  mounted() {
    let tabIndex = this.$route.query.tabIndex;
    this.tabIndex = tabIndex;
    setTimeout(()=>{
      this.updatePage();
      // this.getIsSign();
      // this.getIsCollect();
    },1500)
    
  },

  methods: {
    updatePage() {
      let that = this;
      this.$nextTick(() => {
        let datas = that.AnswerList.paperSubjectList;
        let maxIndex = 0;
        let minIndex = -1;
        if (
          (that.AnswerList.finallyAnswerId != undefined &&
            that.AnswerList.finallyAnswerId != "") ||
          that.AnswerList.finallyAnswerId != null
        ) {
          datas.map((item, index) => {
            if (item.subjectId == that.AnswerList.finallyAnswerId) {
              maxIndex = index;
            } else {
              if (item.subjectList) {
                let min_data = item.subjectList;
                min_data.map((minItem, minIndexNew) => {
                  if (minItem.subjectId == that.AnswerList.finallyAnswerId) {
                    maxIndex = index;
                    minIndex = minIndexNew;
                  }
                });
              }
            }
          });
        }

        if (minIndex !== -1) {
          that.setActiveQuestion(maxIndex, true, minIndex);
        } else {
          that.initIndex = maxIndex;
        }
      });
    },
    getIsSign() {
      if (this.AnswerList.paperSubjectList[this.initIndex].isSign == "2") {
        this.isSignTK = true;
      } else {
        this.isSignTK = false;
      }
    },
    getIsCollect() {
      if (this.AnswerList.paperSubjectList[this.initIndex].isCollect == "2") {
        this.isCollectTK = true;
      } else {
        this.isCollectTK = false;
      }
    },
    addSing(val) {
      let pageQuery = this.$route.query;
      let tabsIndex = pageQuery.tabIndex;
      // 二级判断 做题记录的章节还是考试
      var examTextInfo = localStorage.getItem("tiku_examText");
      let examText = JSON.parse(examTextInfo);
      let items = examText.chapterListItem;
      let obj = new Object();
      if (tabsIndex == "1") {
        obj = {
          subjectId: this.AnswerList.paperSubjectList[this.initIndex].subjectId,
          type: val,
          nodeId: items.nodeId,
          testPaperId: ""
        };
      } else if (tabsIndex == "3" || tabsIndex == "4" || tabsIndex == "5") {
        let testPaperId = pageQuery.testPaperId;
        obj = {
          subjectId: this.AnswerList.paperSubjectList[this.initIndex].subjectId,
          type: val,
          testPaperId: testPaperId
        };
      } else if (tabsIndex == "6" || tabsIndex == "8") {
        if (examText.twoIindex == "0") {
          obj = {
            subjectId: this.AnswerList.paperSubjectList[this.initIndex]
              .subjectId,
            type: val,
            nodeId: items.nodeId,
            testPaperId: ""
          };
        } else {
          obj = {
            subjectId: this.AnswerList.paperSubjectList[this.initIndex]
              .subjectId,
            type: val,

            testPaperId: testPaperId
          };
        }
      }
      add_sign(obj).then(res => {
        if (res.data.code == 0) {
          this.AnswerList.paperSubjectList[this.initIndex].isSign = val;
          this.getIsSign();
        }
      });
    },
    addCooll(val) {
      if (val == 1) {
        let obj = {
          subjectId: this.AnswerList.paperSubjectList[this.initIndex].subjectId
        };
        add_collect(obj).then(res => {
          if (res.data.code == 0) {
            this.AnswerList.paperSubjectList[this.initIndex].isCollect = val;
            this.getIsCollect();
          }
        });
      } else {
        let obj = {
          subjectIds: this.AnswerList.paperSubjectList[this.initIndex].subjectId
        };
        deleted_collect(obj).then(res => {
          if (res.data.code == 0) {
            this.AnswerList.paperSubjectList[this.initIndex].isCollect = val;
            this.getIsCollect();
          }
        });
      }
    },
    setIndex(val) {
      val.paperSubjectList.map((item, index) => {
        if (val.finallyAnswerId == item.subjectId) {
          this.initIndex = index;
          this.getIsSign();
          this.getIsCollect();
        }
      });
    },
    cardChange(el) {
      this.$store.commit("setQuestionSwipeIndex", { index: -1, isShow: false });
      this.isShowAss = false;
      this.getIsSign();
      this.getIsCollect();
    },
    setItemRef(el) {
      this.refList.push(el);
    },
    analysisQuestionn() {
      this.$store.commit("setQuestionSwipeIndex", {
        index: this.initIndex,
        isShow: true
      });
      
      let types = this.AnswerList.paperSubjectList[this.initIndex].classify;
      if (
        types == "4" ||
        types == "10" ||
        types == "11" ||
        types == "5" ||
        types == "6" ||
        types == "7" ||
        types == "8" ||
        types == "9"
      ) {
        this.refList[this.initIndex].startAnaly();
      }
      this.isShowAss = true;
    },
    disAnalysisQuestionn() {
      
      this.$store.commit("setQuestionSwipeIndex", { index: -1, isShow: false });
      this.isShowAss = false;
    },

    answerRadio(res) {
      this.$emit("allEventFn", res);
      //this.subMitList[res.dataIndex] = res;
    },
    setActiveQuestion(index, subjectList, index1) {
      this.initIndex = index;
      if (subjectList) {
        this.refList[this.initIndex].changeAnswer(index1);
      }
      this.$refs.carouselREf.setActiveItem(index);
    },
    preQuestion() {
      if (this.initIndex == 0) {
        this.$message.error("当前是第一题");
      } else {
        if(this.AnswerList.paperSubjectList[this.initIndex].subjectList.length) {
          let curIndex = this.refList[this.initIndex].getAnswer();
          if(curIndex === 0) {
            this.initIndex = this.initIndex - 1;
            if(this.AnswerList.paperSubjectList[this.initIndex].subjectList.length) {
              let newIndex = this.AnswerList.paperSubjectList[this.initIndex].subjectList.length-1;
              this.refList[this.initIndex].setAnswer(newIndex);
              this.$emit("changePaper", this.initIndex,newIndex);
            }else {
              this.$emit("changePaper", this.initIndex);
            }
          }else {
            this.refList[this.initIndex].setAnswer(curIndex-1);
            this.$emit("changePaper", this.initIndex,curIndex-1);
          }
        }else {
          this.initIndex = this.initIndex - 1;
          this.$emit("changePaper", this.initIndex);
        }
        this.$refs.carouselREf.setActiveItem(this.initIndex);
      }
    },
    changePaper(oneLev,twoLev) {
      this.$emit("changePaper", oneLev,twoLev);
    },
    nextQuestion() {
      if (this.AnswerList.paperSubjectList.length - 1 == this.initIndex) {
        let curIndex = this.refList[this.initIndex].getAnswer();
        if(curIndex == 'radio'){
          //最后一题，提示交卷
          this.$confirm("您已经做到最后一题了是否提交呢", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
          }).then(() => {
              this.emit("submitPaper");
          })
          .catch(() => {});
        }else{
          let newIndex = this.AnswerList.paperSubjectList[this.initIndex].subjectList.length;
          if (newIndex && curIndex !== (newIndex - 1)) {
            this.refList[this.initIndex].setAnswer(curIndex + 1);
            this.$emit("changePaper", this.initIndex, curIndex + 1);
          } else {
            //最后一题，提示交卷
            this.$confirm("您已经做到最后一题了是否提交呢", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(() => {
              this.emit("submitPaper");
            })
            .catch(() => {});
          }

        }
        
      } else {
        if(this.AnswerList.paperSubjectList[this.initIndex].subjectList.length) {
          let curIndex = this.refList[this.initIndex].getAnswer();
          let newIndex = this.AnswerList.paperSubjectList[this.initIndex].subjectList.length-1;
          if(curIndex === newIndex) {
            this.initIndex = this.initIndex + 1;
            this.$emit("changePaper", this.initIndex);
          }else {
            this.refList[this.initIndex].setAnswer(curIndex+1);
            this.$emit("changePaper", this.initIndex,curIndex+1);
          }
        }else {
          
          this.initIndex = this.initIndex + 1;
          this.AnswerList.finallyAnswerId = this.AnswerList.paperSubjectList[this.initIndex].subjectId;
          this.$emit("changePaper", this.initIndex);
        }
        this.$refs.carouselREf.setActiveItem(this.initIndex);
      }
    },
    // 计算器
    add(index) {
      //这里就是按键绑定的方法，把获取到的值拼接到rel字符串上
      this.rel += index;
    },
    result() {
      this.rel = eval(this.rel); //这里是用eval方法进行一个计算
      this.rel = String(this.rel); //这里的目的是为了显示在显示栏的数字还是字符串，只有字符串才能进行退格，归零的操作
    },
    del() {
      //这个就是退格的操作，用字符串的substring方法截取，每一次截取都是从第0个开始，到长度的前一个，就相当于退格了。
      this.rel = this.rel.substring(0, this.rel.length - 1);
    },
    clean() {
      //这里就是归零的方法，通过给结果赋一个空字符串的方法来实现归零的操作，当然也可以使用删除的方法，例如unshift方法或者pop方法，直接赋值为空比较简便。
      this.rel = "";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less"  scoped>
.questionArea_top {
  /deep/ .el-carousel__container {
    min-height: 640px;
    .el-carousel__item {
      overflow: auto;
    }
  }
}
.bottom-box {
  color: #5f7cfd;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.jisuan-box {
  /deep/ input {
    width: 50px;
    height: 50px;
    border: 1px solid black;
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    /* background-color: gainsboro; */
    outline: none;
  }
}
</style>

<style lang="less">
.calculator {
  width: 212px !important;

  input {
    width: 50px;
    height: 50px;
    border: 1px solid black;
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    outline: none;
  }
}
</style>