var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tikuAnswer"},[_c('div',{staticClass:"tikuAnswer_header"},[_c('div',{staticClass:"tikuAnswer_header_box"},[_vm._m(0),_c('p',[(
            _vm.tabIndex == '3' ||
            _vm.tabIndex == '4' ||
            _vm.tabIndex == '5' ||
            _vm.twoIindex == '1'
          )?_c('span',[_vm._v("试卷总分"+_vm._s(_vm.AnswerList.paperTotalScore)+"分")]):_vm._e(),(
            _vm.tabIndex == '3' ||
            _vm.tabIndex == '4' ||
            _vm.tabIndex == '5' ||
            _vm.twoIindex == '1'
          )?_c('span',[_vm._v("答题时间"+_vm._s(_vm.AnswerList.paperAnswerTime)+"分钟")]):_vm._e()])])]),_c('div',{staticClass:"tikuAnswer_title"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{
          path: '/tiku_examcenter',
          query: { secondId: this.secondId, firstId: this.firstId },
        }}},[_vm._v("在线题库")]),(_vm.tabIndex == 3 || _vm.tabIndex == 4 || _vm.tabIndex == 5)?_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.examInfo.testPaperName))]):_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.examInfo.nodeName))])],1),_c('div',{staticClass:"tikuAnswer_title_time"},[_c('img',{attrs:{"src":require("@/assets/exam/time.png"),"alt":""}}),_c('span',{staticClass:"tikuAnswer_title_time_text"},[_vm._v("已耗时："+_vm._s(_vm.str))]),_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.submitPaper}},[_vm._v("交卷")])],1)],1),_c('div',{staticClass:"tikuAnswer_main"},[(_vm.AnswerList)?_c('div',{staticClass:"tikuAnswer_main_left"},[_c('answerCard',{ref:"answerCard",attrs:{"AnswerList":_vm.AnswerList},on:{"jumpTo":_vm.jumpTo}})],1):_vm._e(),(_vm.AnswerList)?_c('div',{staticClass:"tikuAnswer_main_right"},[_c('questionArea',{ref:"questionArea",attrs:{"AnswerList":_vm.AnswerList},on:{"allEventFn":_vm.allEventFn,"submitPaper":_vm.submitPaper,"changePaper":_vm.changePaper}})],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"margin-left":"0"}},[_vm._v("中国会计网")])])
}]

export { render, staticRenderFns }