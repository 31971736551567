<template>
  <div class="tiku_radio">
    <div class="tiku_radio_title">多项选择题</div>
    <div class="tiku_radio_question_box">
      <div class="tiku_radio_question_title">
        {{ data.questionIndex }}、{{ data.subjectTitle }}
<!--        {{ dataIndex + 1 }}、{{ data.subjectTitle }}-->
      </div>
      <div class="tiku_radio_question_radio_box">
        <el-checkbox-group v-model="checkList" @change="radioChange">
          <el-checkbox
            class="f-v-c"
            v-for="(item, index) in data.answerList"
            :key="index"
            :label="item.answerId"
          >
            {{ item.indexes }}、{{ item.answerName }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div v-if="isShowJX" class="tiku_radio_radioAnalysis_header">
      <div class="tiku_radio_radioAnalysis_header_MyQuestion">
        <p>
          <span>您的作答：</span><span>{{ setMyAnwser(anaList) }}</span>
        </p>
        <p>
          <span>参考答案：</span><span>{{ setRightAnwser(anaList) }}</span>
        </p>
        <p
          v-if="
            questionObj.totalAccuracy != '' &&
            questionObj.totalAccuracy != null &&
            questionObj.totalAccuracy != undefined
          "
        >
          <span>全站正确率：</span>
          <span>{{ questionObj.totalAccuracy }}</span>
        </p>
      </div>
      <div class="tiku_radio_radioAnalysis_header_numQuestion">
        <p>
          <span>累计作答：</span><span>{{ questionObj.countNumber }}次</span>
        </p>
        <p>
          <span>答对：</span><span>{{ questionObj.rightNumber }}次</span>
        </p>
        <p>
          <span>答错：</span>
          <span>{{ questionObj.errorNumber }}次</span>
        </p>
      </div>
      <div class="tiku_radio_radioAnalysis_header_anwserAna">
        <span>答题解析:</span>
        <span v-html="anaList.analysisText"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { subject_analysis,questionStatistics } from "@/api/exam.js";
import { Loading } from "element-ui";
export default {
  props: ["data", "dataIndex"],
  components: {},
  data() {
    return {
      checkList: [],
      isShowJX: false,
      anaList: {},
      questionObj:{},
    };
  },
  name: "checkboxCom",
  computed: {
    Obj() {
      return this.$store.state.studyList.questionSwipeIndex.index;
    },
  },
  watch: {
    Obj(oldValue, newValue) {
      if (this.$store.getters.getQuestionSwipeIndex.index == this.dataIndex) {
        this.initAnalysis();
      } else {
        this.isShowJX = false;
      }
      // if(this.$store.getters.getResolution_method.key == 'header_res'){

      // }else{
      //   this.isShowJX = 'yes';
      // }
    },
  },

  methods: {
    getAnswer(){
      // this.save_subject_Tab(this.checkList);
      return 'radio';
    },
    radioChange(val) {
      let obj = {
        subjectId: this.data.subjectId,
        answerId: val,
        dataIndex: this.dataIndex,
        type: "checkbox",
      };
      this.$emit("answerRadio", obj);
    },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        if (
          list.doneAnswerIds != null &&
          list.doneAnswerIds.indexOf(",") != -1
        ) {
          let items = list.doneAnswerIds.split(",");
          let val = [];
          items.map((res) => {
            list.answerList.map((item) => {
              if (res === item.answerId) {
                val.push(item);
              }
            });
          });
          if (val.length == 0) {
            return "/";
          } else {
            let str = "";
            val.map((item) => {
              str += item.indexes;
            });
            return str;
          }
        } else {
          let val = list.answerList.filter((item) => {
            return list.doneAnswerIds === item.answerId;
          });
          if (val.length == 0) {
            return "/";
          } else {
            return val[0].indexes;
          }
        }
      }
    },
    setRightAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return item.isRight == "1";
        });
        let str = "";
        val.map((item) => {
          str += item.indexes;
        });
        return str;
      }
    },
    initAnalysis() {
      this.loading = Loading.service({
        text: "解析中...",
        background: "rgba(0, 0, 0, 0.1)",
      });
      subject_analysis({ subjectId: this.data.subjectId }).then((res) => {
        this.isShowJX = this.$store.getters.getQuestionSwipeIndex.isShow;
        this.anaList = res.data.data;
        this.loading.close();
      });
      questionStatistics({subjectId: this.data.subjectId}).then((res)=>{
        this.questionObj = res.data.data;
      })
    },
  },
  created() {},
  mounted() {
    this.checkList = this.data.doneAnswerIds?this.data.doneAnswerIds.split(','):[]
  },
};
</script>