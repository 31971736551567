<template>
    <div class="tiku_indeterminate">
      <div class="tiku_indeterminate_header">{{ setTitle(curData) }}</div>
      <div class="tiku_indeterminate_question">
        <div class="tiku_indeterminate_question_title update_font_size">
          <span>{{data.questionIndex}}、</span>
<!--          <span>{{dataIndex+1}}、</span>-->
          <span v-html="data.subjectTitle"></span>
          <img v-if="data.subjectTitlePic" :src="data.subjectTitlePic" class="subjectTitlePic">
        </div>
      </div>
      <div class="tiku_indeterminate_inputBox">
        <div class="tiku_indeterminate_inputBox_header">
          <div class="tiku_indeterminate_inputBox_header_item">
            <span v-for="(item,index) in data.subjectList" :key="index" :class="smallQuestionIndex === index?'checked':''" @click="tabSmallQuestion(item,index)">第{{index+1}}小题</span>
          </div>
        </div>
      </div>
      <div class="tiku_indeterminate_question_text">
        {{curData.subjectTitle}}
        <img v-if="curData.subjectTitlePic" :src="curData.subjectTitlePic" class="subjectTitlePic">
      </div>
      <div class="examText_main_right_checkBox_question_option">
        <div class="examText_main_right_checkBox_question_item radio-Group">
          <el-radio-group v-model="checkList" @change="save_subject_Tab" style="width:100%">
            <div class="radio_box" v-for="(item,index) in curData.answerList">
                <el-radio class="f-v-c"  :key="index" :label="item.answerId">{{item.indexes}}、{{item.answerName}}<img :src="item.answerPic" class="answerPic" alt="" v-if="item.type==2"></el-radio>
            </div>
            
          </el-radio-group>
        </div>
      </div>
  
  <!--答案解析-->
      <div class="tiku_indeterminate_radioAnalysis_nalysisWarp">
        <div v-if="isShowJX" class="tiku_indeterminate_radioAnalysis_header">
          <div class="tiku_indeterminate_radioAnalysis_header_MyQuestion">
            <p>
              <span>您的作答：</span><span>{{ setMyAnwser(anaList) }}</span>
            </p>
            <p>
              <span>参考答案：</span><span>{{ setRightAnwser(anaList) }}</span>
            </p>
            <p
                v-if="
              questionObj.totalAccuracy != '' &&
              questionObj.totalAccuracy != null &&
              questionObj.totalAccuracy != undefined
            "
            >
              <span>全站正确率：</span>
              <span>{{ questionObj.totalAccuracy }}</span>
            </p>
          </div>
          <div class="tiku_indeterminate_radioAnalysis_header_numQuestion">
            <p>
              <span>累计作答：</span><span>{{ questionObj.countNumber }}次</span>
            </p>
            <p>
              <span>答对：</span><span>{{ questionObj.rightNumber }}次</span>
            </p>
            <p>
              <span>答错：</span>
              <span>{{ questionObj.errorNumber }}次</span>
            </p>
          </div>
          <div class="tiku_indeterminate_radioAnalysis_header_anwserAna">
            <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_title">答题解析：</div>
            <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_text">
              <p v-html="anaList.analysisText"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {subject_analysis,questionStatistics} from "@/api/exam";
  import { Loading } from "element-ui";
  export default {
    props: ["data", "dataIndex"],
    components: {},
    data() {
      return {
        isShowJX: false,
        smallQuestionIndex: 0,
        anaList: {},
        questionObj:{},
        curData: {},
        checkList: []
      };
    },
    name: "radioGroup",
    computed: {
      Obj() {
        return this.$store.state.studyList.questionSwipeIndex.index;
      },
    },
    watch: {
      Obj(oldValue, newValue) {
        if (this.$store.getters.getQuestionSwipeIndex.index == this.dataIndex) {
          //this.initAnalysis();
        } else {
          //this.isShowJX = false;
        }
      },
    },
    methods: {
      changeAnswer(index){
        this.smallQuestionIndex = index;
        this.isShowJX = false;
        this.curData = this.data.subjectList[index];
        this.checkList = this.curData.doneAnswerIds?this.curData.doneAnswerIds:[];
      },
      getAnswer(){
        // this.save_subject_Tab(this.checkList);
        return this.smallQuestionIndex;
      },
      setAnswer(index){
        this.smallQuestionIndex = index;
        this.isShowJX = false;
        this.curData = this.data.subjectList[index];
        this.checkList = this.curData.doneAnswerIds?this.curData.doneAnswerIds:[];
      },
      startAnaly(){
        this.initAnalysis();
      },
      setMyAnwser(list) {
        if (list.answerList && list.answerList.length > 0) {
          let val = list.answerList.filter((item) => {
            return list.doneAnswerIds === item.answerId;
          });
          if (val.length == 0) {
            return "/";
          } else {
            return val[0].indexes;
          }
        }
      },
      setRightAnwser(list) {
        if (list.answerList && list.answerList.length > 0) {
          let val = [];
          list.answerList.map((item) => {
            if(item.isRight === 1) {
              val.push(item.indexes)
            }
          });
          return val.join(',');
        }
      },
      tabSmallQuestion(val,index) {
        // this.smallQuestionIndex = val.subjectId;
        this.smallQuestionIndex = index;
        this.isShowJX = false;
        this.curData = this.data.subjectList[index];
        this.checkList = this.curData.doneAnswerIds?this.curData.doneAnswerIds:[];
        let subjectId = this.data.subjectList.length?this.data.subjectList[this.smallQuestionIndex].subjectId:this.data.subjectId;
        let obj = {
          subjectId: subjectId,
          dataIndex: this.dataIndex,
          type: "radio",
        };
        this.$emit("answerRadio", obj);
        this.$emit("changePaper", this.dataIndex,index);
      },
      save_subject_Tab(val) {
        let subjectId = this.data.subjectList.length?this.data.subjectList[this.smallQuestionIndex].subjectId:this.data.subjectId;
        let obj = {
          subjectId: subjectId,
          answerId: val,
          dataIndex: this.dataIndex,
          type: "radio",
        };
        this.$emit("answerRadio", obj);
      },
      changeQuestion(type) {
        if(type === 'prev'&&this.smallQuestionIndex===0) {
  
        }else if(type === 'next'&&this.smallQuestionIndex===this.data.subjectList.length-1) {
  
        }else {
          let index = type === 'prev'?this.smallQuestionIndex-1:this.smallQuestionIndex+1;
          this.tabSmallQuestion(index);
        }
      },
      initAnalysis() {
        this.loading = Loading.service({
          text: "解析中...",
          background: "rgba(0, 0, 0, 0.1)",
        });
        subject_analysis({ subjectId: this.curData.subjectId }).then((res) => {
          this.isShowJX = !this.isShowJX;
          // this.isShowJX = this.$store.getters.getQuestionSwipeIndex.isShow;
          this.anaList = res.data.data;
          this.loading.close();
        });
        questionStatistics({subjectId: this.curData.subjectId}).then((res)=>{
          this.questionObj = res.data.data;
        })
      },
      setTitle(item){
        switch(item.classify){
          case "1":
            return "单项选择题";
            break;
          case "2":
            return "多项选择题";
            break;
          case "3":
            return "判断题";
            break;
          case "4":
            return "不定项选择题";
            break;
          case "5":
            return "计算分析题";
            break;
          case "6":
            return "综合题";
            break;
          case "7":
            return "简答题";
            break;
          case "8":
            return "计算回答题";
            break;
          case "9":
            return "案例分析题";
            break;
          case "10":
            return "计算题";
            break;
          case "11":
            return "综合分析题";
            break;
  
        }
  
      },
    },
    created() {
  
    },
    mounted() {
      this.$nextTick(()=>{
        this.curData = this.data.subjectList[0];
        this.checkList = this.curData.doneAnswerIds?this.curData.doneAnswerIds:[];
      })
      
    },
  };
  </script>
  <style>
  .radio_box{
      
    display: flex;
    justify-content: left;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
  }
 </style>